.sectionContainer {
  flex-basis: 1;
  box-sizing: border-box;
  display: block;
  padding: 2% 5% 5% 5%;
  align-items: center;
  position: relative;
  background-color: var(--azulMedia);
  }
.sectionContainer h2 {
  text-align: center;
  color: var(--branco);
  font-size: 60px;
  margin: 2% 0;
} 
.divContainer{
  margin: 2rem 0;
  text-align: center;  
}
.boxTexte{
  background-color: var(--azulEscuro);
   padding: 0 0 1rem 0;
}
.title{  
  padding: 15% 5%;  
  font-size: 32px;
  background-color: var(--azulEscuro);
  color: var(--branco);
}
.texto{
  text-align: left;
  max-height: 125px;
  overflow-y: scroll;
  padding: 1rem;
  font-size: 20px;
  background-color: var(--azulClaro);
}
p::-webkit-scrollbar {
  width: 8px; 
}

p::-webkit-scrollbar-thumb {
  background-color: var(--branco); 
  border-radius: 25px;
}

p::-webkit-scrollbar-track {
  background-color: var(--azulClaro); 
}

@media (max-width: 1440px) {
  .sectionContainer {
    flex-basis: 1;
    box-sizing: border-box;
    display: block;
    padding: 2% 5% 5% 5%;
    align-items: center;
    position: relative;
    background-color: var(--azulMedia);
    }
  .sectionContainer h2 {
    text-align: center;
    color: var(--branco);
    font-size: 48px;
    margin: 2% 0;
  } 
  .divContainer{
    margin: 2rem 0;
    text-align: center;  
  }
  .boxTexte{
    background-color: var(--azulEscuro);
     padding: 0 0 1rem 0;
  }
  .title{  
    padding: 12% 5%;  
    font-size: 28px;
    background-color: var(--azulEscuro);
    color: var(--branco);
  }
  .texto{
    text-align: left;
    max-height: 125px;
    overflow-y: scroll;
    padding: 1rem;
    font-size: 18px;
    background-color: var(--azulClaro);
  }
}


@media (max-width: 1024px) {
  .sectionContainer {
    flex-basis: 1;
    box-sizing: border-box;
    display: block;
    padding: 2% 5% 5% 5%;
    align-items: center;
    position: relative;
    background-color: var(--azulMedia);
    }
  .sectionContainer h2 {
    text-align: center;
    color: var(--branco);
    font-size: 36px;
    margin: 2% 0;
  } 
  .divContainer{
    margin: 2rem 0;
    text-align: center;  
  }
  .boxTexte{
    background-color: var(--azulEscuro);
     padding: 0 0 1rem 0;
  }
  .title{  
    padding: 10% 5%;  
    font-size: 18px;
    background-color: var(--azulEscuro);
    color: var(--branco);
  }
  .texto{
    text-align: left;
    max-height: 100px;
    overflow-y: scroll;
    padding: 1rem;
    font-size: 14px;
    background-color: var(--azulClaro);
  }
}

@media (max-width: 768px) {
  .sectionContainer {
    flex-basis: 1;
    box-sizing: border-box;
    display: block;
    padding: 2% 5% 5% 5%;
    align-items: center;
    position: relative;
    background-color: var(--azulMedia);
    }
  .sectionContainer h2 {
    text-align: center;
    color: var(--branco);
    font-size: 36px;
    margin: 2% 0;
  } 
  .divContainer{
    margin: 2rem 0;
    text-align: center;  
  }
  .boxTexte{
    background-color: var(--azulEscuro);
     padding: 0 0 1rem 0;
  }
  .title{  
    padding: 10% 5%;  
    font-size: 14px;
    background-color: var(--azulEscuro);
    color: var(--branco);
  }
  .texto{
    text-align: left;
    max-height: 100px;
    overflow-y: scroll;
    padding: .5rem;
    font-size: 14px;
    background-color: var(--azulClaro);
  }
}

@media (max-width: 500px) {
  .sectionContainer {
    flex-basis: 1;
    box-sizing: border-box;
    display: block;
    padding: 2% 10% 5% 10%;
    align-items: center;
    position: relative;
    background-color: var(--azulMedia);
    }
  .sectionContainer h2 {
    text-align: center;
    color: var(--branco);
    font-size: 24px;
    margin: 2% 0;
  } 
  .divContainer{
    margin: 2rem 0;
    text-align: center;  
  }
  .boxTexte{
    background-color: var(--azulEscuro);
     padding: 0 0 1rem 0;
  }
  .title{  
    padding: 8% 5%;  
    font-size: 22px;
    background-color: var(--azulEscuro);
    color: var(--branco);
  }
  .texto{
    text-align: left;
    max-height: 100px;
    overflow-y: scroll;
    padding: .5rem;
    font-size: 18px;
    background-color: var(--azulClaro);
  }
}