.section{
    margin-top: 5%;
    padding: 2% 0;
    text-align: center;
    background-color: var(--azulClaro);
}
.tituloTopo{
    color: var(--azulEscuro);
  font-size: 60px;
  margin: 0;
  font-weight: 600;
}
.section h2{
    padding: 0 15% 0 15%;
    color: var(--branco);
    font-size: 36px;
    padding: .5rem;
    margin: 0;
    background-color: var(--azulEscuro);
}
.divVisao{    
    display: flex;
    justify-content: space-between;  
    margin: 2% 15%;  
}
.section li{
    border: 5px solid var(--azulEscuro);
    border-radius: 15px;   
    background-color: var(--branco);
    width: 45%;
}
.section p{
    font-size: 20px;
    color: var(--azulEscuro);
    padding: 1rem;
    font-weight: 600;
}
.strong{
    color: var(--azulMedia)
}

.divMission{
    box-sizing: border-box;
    margin: 2% 15%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    border: 5px solid var(--azulEscuro);
    background-color: var(--branco);
}
.section h3{
    color: var(--branco);
    font-size: 36px;
    padding: .5rem;
    background-color: var(--azulEscuro);
}
.divMission div{
    display: block;
    margin: 0;
    padding-top: 1%;   
}
.divMission h4{
    color: var(--azulMedia);
    font-size: 24px;
    text-align: center;
    margin: 0;
}
.divMission p{
    margin: 1% 5%;
    font-size: 14px;
    padding: 0;
    line-height: 1.3;
}

@media (max-width: 1440px) {
    .section{
        margin-top: 5%;
        padding: 2% 0;
        text-align: center;
        background-color: var(--azulClaro);
    }
    .tituloTopo{
        color: var(--azulEscuro);
      font-size: 48px;
      margin: 0;
    }
    .section h2{
        padding: 0 15% 0 15%;
        color: var(--branco);
        font-size: 24px;
        padding: .5rem;
        margin: 0;
        background-color: var(--azulEscuro);
    }
    .divVisao{    
        display: flex;
        justify-content: space-between;  
        margin: 2% 15%;  
    }
    .section li{
        border: 5px solid var(--azulEscuro);
        border-radius: 15px;   
        background-color: var(--branco);
        width: 45%;
    }
    .section p{
        font-size: 18px;
        color: var(--azulEscuro);
        padding: 1rem;
        font-weight: 600;
    }
    .strong{
        color: var(--azulMedia)
    }
    
    .divMission{
        box-sizing: border-box;
        margin: 2% 15%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        border: 5px solid var(--azulEscuro);
        background-color: var(--branco);
    }
    .section h3{
        color: var(--branco);
        font-size: 24px;
        padding: .5rem;
        background-color: var(--azulEscuro);
    }
    .divMission div{
        display: block;
        margin: 0;
        padding-top: 1%;   
    }
    .divMission h4{
        color: var(--azulMedia);
        font-size: 24px;
        text-align: center;
        margin: 0;
    }
    .divMission p{
        margin: 1% 5%;
        font-size: 14px;
        padding: 0;
    }
}


@media (max-width: 768px) {
    .section{
        margin-top: 5%;
        padding: 2% 0;
        text-align: center;
        background-color: var(--azulClaro);
    }
    .tituloTopo{
        color: var(--azulEscuro);
      font-size: 36px;
      margin: 0;
    }
    .section h2{
        padding: 0 10% 0 10%;
        color: var(--branco);
        font-size: 20px;
        padding: .5rem;
        margin: 0;
        background-color: var(--azulEscuro);
    }
    .divVisao{    
        display: flex;
        justify-content: space-between;  
        margin: 2% 10%;  
    }
    .section li{
        border: 5px solid var(--azulEscuro);
        border-radius: 15px;   
        background-color: var(--branco);
        width: 45%;
    }
    .section p{
        font-size: 14px;
        color: var(--azulEscuro);
        padding: 1rem;
        font-weight: 600;
    }
    .strong{
        color: var(--azulMedia)
    }
    
    .divMission{
        box-sizing: border-box;
        margin: 2% 10%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        border: 5px solid var(--azulEscuro);
        background-color: var(--branco);
    }
    .section h3{
        color: var(--branco);
        font-size: 20px;
        padding: .5rem;
        background-color: var(--azulEscuro);
    }
    .divMission div{
        display: block;
        margin: 0;
        padding-top: 1%;   
    }
    .divMission h4{
        color: var(--azulMedia);
        font-size: 20px;
        text-align: center;
        margin: 0;
    }
    .divMission p{
        margin: 1% 5%;
        font-size: 14px;
        padding: 0;
    }
}


@media (max-width: 500px) {
    .section{
        margin-top: 5%;
        padding: 2% 0;
        text-align: center;
        background-color: var(--azulClaro);
    }
    .tituloTopo{
        color: var(--azulEscuro);
      font-size: 24;
      margin: 0;
    }
    .section h2{
        padding: 0 10% 0 10%;
        color: var(--branco);
        font-size: 20px;
        padding: .5rem;
        margin: 0;
        background-color: var(--azulEscuro);
    }
    .divVisao{    
        display: block;
        justify-content: center;  
        margin: 2% 4%;  
    }
    .section li{
        border: 5px solid var(--azulEscuro);
        border-radius: 15px;   
        background-color: var(--branco);
        margin: 1rem 0;
        width: 100%;
    }
    .section p{
        font-size: 14px;
        color: var(--azulEscuro);
        padding: 1rem;
        font-weight: 600;
    }
    .strong{
        color: var(--azulMedia)
    }
    
    .divMission{
        box-sizing: border-box;
        margin: 2% 4%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        border: 5px solid var(--azulEscuro);
        background-color: var(--branco);
    }
    .section h3{
        color: var(--branco);
        font-size: 20px;
        padding: .5rem;
        background-color: var(--azulEscuro);
    }
    .divMission div{
        display: block;
        margin: 0;
        padding-top: 1%;   
    }
    .divMission h4{
        color: var(--azulMedia);
        font-size: 20px;
        text-align: center;
        margin: 0;
    }
    .divMission p{
        margin: 1% 5%;
        font-size: 14px;
        padding: 0;
    }
}


@media (max-width: 425px) {
    .section h2{
        padding: 0 10% 0 10%;
        color: var(--branco);
        font-size: 16px;
        padding: .5rem;
        margin: 0;
        background-color: var(--azulEscuro);
    }
    .tituloTopo{
        color: var(--azulEscuro);
      font-size: 24px;
      margin: 0;
    }
    .divVisao{    
        display: block;
        justify-content: center;  
        margin: 2% 4%;  
    }
    .section li{
        border: 5px solid var(--azulEscuro);
        border-radius: 15px;   
        background-color: var(--branco);
        margin: 1rem 0;
        width: 100%;
    }
    .section p{
        font-size: 14px;
        color: var(--azulEscuro);
        padding: 1rem;
        font-weight: 600;
        font-size: 12px;
    }
    .strong{
        color: var(--azulMedia)
    }
    
    .divMission{
        box-sizing: border-box;
        margin: 2% 4%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        border: 5px solid var(--azulEscuro);
        background-color: var(--branco);
    }
    .section h3{
        color: var(--branco);
        font-size: 16px;
        padding: .5rem;
        background-color: var(--azulEscuro);
    }
    .divMission div{
        display: block;
        margin: 0;
        padding-top: 1%;   
    }
    .divMission h4{
        color: var(--azulMedia);
        font-size: 16px;
        text-align: center;
        margin: 0;
    }
    .divMission p{
        margin: 1% 5%;
        font-size: 12px;
        padding: 0;        
    }
}