.header {
    background-color: rgba(163, 203, 229,0);
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: 0.3 ease;
  }
  .btn{
    margin-right: 1rem;
  }
  .menu{
    display: none;
  }
  
  .hamburgerButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 30px;
    line-height: 1;
    padding: 0.5rem;
    transition: color 0.3s;
    color: var(--azulEscuro);
  }
  .hamburgerButton::after {
    background: var(--azulMedia);
    color: var(--azulMedia);
  }
  
  .mobileMenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(49, 174, 220,.8);
    box-shadow: 0 2px 4px rgba(49, 174, 220, 0.5);
    padding: 10px;
    transition: all 0.3s;
  }
  
  /* Classe para animar a abertura do menu */
  .open {
    display: block;
    transform: scaleY(1);
    transform-origin: top;
    opacity: 1;
    
  }
  
  /* Estilo dos links no menu móvel */
  .mobileMenu a {
    display: block;
    color: var(--branco);
    text-decoration: none;
    padding: 5px 0;
    transition: color 0.3s;
  }
  
  /* Efeito de cor no hover dos links do menu móvel */
  .mobileMenu a:hover {
    color: var(--azulEscuro);
  }
  
  .header.Rolando{
    background-color: rgba(163, 203, 229,1)
  }
  .divHome {
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    padding: 0;
  }
  .divImage {
    padding: 0.7rem 0;
  }
  
  .img {
    max-width: 200px;
  }
  .nav {
    display: flex;
  }
  .botao {
    text-decoration: none;
    color: var(--azulEscuro);
    cursor: pointer;
    transition: .2s ease-in;
  }
  .botao:hover {
    color: var(--azulMedia);
  }
  .caixaBtn {
  }
  .btn {
    cursor: pointer;
    text-decoration: none;
    background-color: var(--azulEscuro);
    border: var(--azulEscuro) solid 2px;
    border-radius: 15px;
    padding: 0.375rem 0.5rem;
    color: white;
    font-size: 18px;
    white-space: nowrap;
    font-weight: 600;
    transition: .2s ease;
  }
  .btn:hover{
    color:var(--azulMedia);
    background-color: var(--azulClaro);
  }
  .li {
    font-size: 18px;
    font-weight: 600;
    padding: 0 1rem;
    text-decoration: none;
  }
  
  
  @media (max-width: 1440px) {  
    .btn {
      cursor: pointer;
      text-decoration: none;
      background-color: var(--azulEscuro);
      border: var(--azulEscuro) solid 2px;
      border-radius: 15px;
      padding: 0.375rem 0.5rem;
      color: white;
      font-size: 16px;
      white-space: nowrap;
      font-weight: 600;
      transition: .2s ease;
    }
    .btn:hover{
      color:black;
      background-color: var(--azulClaro);
    }
    .li {
      font-size: 16px;
      font-weight: 600;
      padding: 0 1rem;
    }
    .img {
      max-width: 220px;
    }
  }
  @media (max-width: 1024px) {  
    .btn {
      cursor: pointer;
      text-decoration: none;
      background-color: var(--azulEscuro);
      border: var(--azulEscuro) solid 2px;
      border-radius: 15px;
      padding: 0.375rem 0.5rem;
      color: white;
      font-size: 14px;
      white-space: nowrap;
      font-weight: 600;
      transition: .2s ease;
    }
    .btn:hover{
      color:black;
      background-color: var(--azulClaro);
    }
    .li {
      font-size: 14px;
      font-weight: 600;
      padding: 0 1rem;
    }
    .img {
      max-width: 200px;
    }
  }
  @media (max-width: 768px) {  
    .btn {
      cursor: pointer;
      text-decoration: none;
      background-color: var(--azulEscuro);
      border: var(--azulEscuro) solid 2px;
      border-radius: 15px;
      padding: 0.1rem 0.3rem;
      color: white;
      font-size: 9px;
      white-space: nowrap;
      font-weight: 600;
      transition: .2s ease;
    }
    .btn:hover{
      color:black;
      background-color: var(--azulClaro);
    }
    .li {
      font-size: 11px;
      font-weight: 600;
      padding: 0 1rem;
    }
    .liMenu{
      font-size: 16px;
      font-weight: 600;
      padding: 0 1rem;
    }
    .img {
      max-width: 125px;
    }  
  }
  @media (max-width: 525px) {  
    .divHome{
      display: none;
    }
    .menu{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .btn{
      margin-right: 1rem;
    }
  }