.section {
  border-bottom: 2px solid var(--azulMedia);
  position: relative;
}
.boxContainer {
  padding: 10% 0 8% 0;
}
.container {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--azulMedia);
}
.divTexto {
  box-sizing: border-box;
  display: block;
  align-items: center;
  z-index: 1;
  padding-top: 10%;
  padding-bottom: 8%;
  padding-left: 5%;
  color: var(--branco);
}
.titulo {
  font-size: 48px;
  margin: 0;
  overflow: hidden;
}
.titulo span {
  position: relative;  
}
.h3Titulo{
  background-color: #45467E;
  font-size: 48px;;
  margin: 0;
  overflow: hidden
}

.titulo span::before {
  content: "";
  color: var(--azulEscuro);
  animation: palavras 15s infinite ;
}

@keyframes palavras {    
  0%,
  12.5% {
    content: " O ECOSSISTEMA FARMACÊUTICO. ";
  }
  12.6%,
  25% {
    content: " A INDÚSTRIA FARMACÊUTICA. ";
  }
  25.1%,
  32.5% {
    content: " O VAREJO FARMACÊUTICO. ";
  }
  45%,
  45.1% {
    content: " AS DISTRIBUIDORAS. ";
  }
  57.5% {
    content: " OS HOSPITAIS. ";
  }
  57.6%,
  70% {
    content: " AS STARTUPS DE SAÚDE. ";
  }
  70.1%,
  82.5% {
    content: " O GOVERNO.";
  }
  72.6%,
  100% {
    content: " A ACADEMIA. ";
  }
}

.img {
  position: absolute;
  right: 7vw;
  bottom: 0;
  width: 40vw;
}

@media (max-width: 1440px) {
  .boxContainer {
    padding: 10% 0 8% 0;
  }
  .divTexto {
    box-sizing: border-box;
    display: block;
    align-items: center;
    z-index: 1;
    padding-top: 10%;
    padding-bottom: 8%;
    padding-left: 5%;
    color: var(--branco);
  }
  .titulo {
    font-size: 40px;
    margin: 0;
    overflow: hidden;
  }
  .titulo span {
    position: relative;  
  }
  .h3Titulo{
    background-color: #45467E;
    font-size: 40px;;
    margin: 0;
    overflow: hidden
  }
  .img {
    position: absolute;
    right: 5vw;
    bottom: 0;
    width: 40vw;
  }
  
}
@media (max-width: 1024px) {
  .boxContainer {
    padding: 10% 0 8% 0;
  }
  .divTexto {
    box-sizing: border-box;
    display: block;
    align-items: center;
    z-index: 1;
    padding-top: 10%;
    padding-bottom: 8%;
    padding-left: 5%;
    color: var(--branco);
  }
  .titulo {
    font-size: 28px;
    margin: 0;
    overflow: hidden;
  }
  .titulo span {
    position: relative;  
  }
  .h3Titulo{
    background-color: #45467E;
    font-size: 28px;;
    margin: 0;
    overflow: hidden
  }
  .img {
    position: absolute;
    right: 4vw;
    bottom: 0;
    width: 40vw;
  }
}
@media (max-width: 768px) {
  .boxContainer {
    padding: 10% 0 8% 0;
  }
  .divTexto {
    box-sizing: border-box;
    display: block;
    align-items: center;
    z-index: 1;
    padding-top: 10%;
    padding-bottom: 8%;
    padding-left: 5%;
    color: var(--branco);
  }
  .titulo {
    font-size: 22px;
    margin: 0;
    overflow: hidden;
  }
  .titulo span {
    position: relative;  
  }
  .h3Titulo{
    background-color: #45467E;
    font-size: 22px;;
    margin: 0;
    overflow: hidden
  }
  .img {
    position: absolute;
    right: 4vw;
    bottom: 0;
    width: 40vw;
  }
}


@media (max-width: 500px) {
  .boxContainer {
    padding: 10% 0 8% 0;
  }
  .divTexto {
    box-sizing: border-box;
    display: block;
    align-items: center;
    z-index: 1;
    padding-top: 10%;
    padding-bottom: 8%;
    padding-left: 5%;
    color: var(--branco);
  }
  .titulo {
    font-size: 22px;
    margin: 0;
    overflow: hidden;
  }
  .titulo span {
    position: relative;  
  }
  .h3Titulo{
    background-color: #45467E;
    font-size: 22px;;
    margin: 0;
    overflow: hidden
  }
  .img {
   display: none;
  }
}

@media (max-width: 425px) {
  .boxContainer {
    padding: 10% 0 8% 0;
  }
  .divTexto {
    box-sizing: border-box;
    display: block;
    align-items: center;
    z-index: 1;
    padding-top: 10%;
    padding-bottom: 8%;
    padding-left: 5%;
    color: var(--branco);
  }
  .titulo {
    font-size: 18px;
    margin: 0;
    overflow: hidden;
  }
  .titulo span {
    position: relative;  
  }
  .h3Titulo{
    background-color: #45467E;
    font-size: 18px;;
    margin: 0;
    overflow: hidden
  }
  .img {
   display: none;
  }
}