.footer{
    margin: 0;
}
.section{
    display: flex;
    background-color: var(--azulMedia);
    justify-content: center;
    align-items: top;
    padding: 2% 0;
}.divSocial{
    padding-right: 2%;
}
.divSocial img{
    width: 72px;
    color: var(--branco);
    margin: 0.3rem 0 0 1rem;
}
.divSocial p{
    color:#004AAD;
    font-weight: 600;
    padding-bottom: 5rem;
}
.divSocial a{
    cursor: pointer;
}
.Logo{
    border-left: 2px solid #004AAD;
    border-right: 2px solid #004AAD;
    padding: 0 2rem;
}
.Logo img{
    width: 500px;
}
.divLogo{
    display: block;
    text-align: center;
    padding-left: 2%;
}
.divLogo p{
    color:#004AAD;
    font-weight: 600;
    padding-bottom: 1rem;
}
.divLogo img{
    width: 150px;
}
.divContato h4{
    color: var(--branco);
    font-size: 24px;
    margin: 1rem 0;
}
.assinatura {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    justify-content: center;
    margin: 0;
    background-color: var(--azulEscuro);
    padding: 1rem 0;
  }
  
  .assinaturaAutor {
    cursor: pointer;
    text-decoration: none;
    margin-left: 0.3rem;
    color: white;
    border-bottom: 1px white dashed;
  }
  @media (max-width: 1440px) {
    .footer{
        margin: 0;
    }
    .section{
        display: flex;
        background-color: var(--azulMedia);
        justify-content: center;
        align-items: top;
        padding: 2% 0;
    }.divSocial{
        padding-right: 2%;
    }
    .divSocial img{
        width: 72px;
        color: var(--branco);
        margin: 0.3rem 0 0 1rem;
    }
    .divSocial p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: 4rem;
    }
    .divSocial a{
        cursor: pointer;
    }
    .Logo{
        border-left: 2px solid #004AAD;
        border-right: 2px solid #004AAD;
        padding: 0 2rem;
    }
    .Logo img{
        width: 400px;
    }
    .divLogo{
        display: block;
        text-align: center;
        padding-left: 2%;
    }
    .divLogo p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: 1rem;
    }
    .divLogo img{
        width: 125px;
    }
    .assinatura {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        justify-content: center;
        margin: 0;
        background-color: var(--azulEscuro);
        padding: 1rem 0;
      }
      
      .assinaturaAutor {
        cursor: pointer;
        text-decoration: none;
        margin-left: 0.3rem;
        color: white;
        border-bottom: 1px white dashed;
      }
  }

  
  @media (max-width: 1024px) {
    .footer{
        margin: 0;
    }
    .section{
        font-size: 14px;
        display: flex;
        background-color: var(--azulMedia);
        justify-content: center;
        align-items: top;
        padding: 2% 0;
    }.divSocial{
        padding-right: 2%;
    }
    .divSocial img{
        width: 48px;
        color: var(--branco);
        margin: 0.3rem 0 0 1rem;
    }
    .divSocial p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: 4rem;
    }
    .divSocial a{
        cursor: pointer;
    }
    .Logo{
        border-left: 2px solid #004AAD;
        border-right: 2px solid #004AAD;
        padding: 0 2rem;
    }
    .Logo img{
        width: 300px;
    }
    .divLogo{
        display: block;
        text-align: center;
        padding-left: 2%;
    }
    .divLogo p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: 1rem;
    }
    .divLogo img{
        width: 100px;
    }
    .assinatura {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        justify-content: center;
        margin: 0;
        background-color: var(--azulEscuro);
        padding: 1rem 0;
      }
      
      .assinaturaAutor {
        cursor: pointer;
        text-decoration: none;
        margin-left: 0.3rem;
        color: white;
        border-bottom: 1px white dashed;
      }
  }
  
  @media (max-width: 768px) {
    .footer{
        margin: 0;
    }
    .section{
        font-size: 14px;
        display: flex;
        background-color: var(--azulMedia);
        justify-content: center;
        align-items: top;
        padding: 2% 0;
    }.divSocial{
        padding-right: 2%;
    }
    .divSocial img{
        width: 36px;
        color: var(--branco);
        margin: 0.3rem 0 0 1rem;
    }
    .divSocial p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: 2rem;
    }
    .divSocial a{
        cursor: pointer;
    }
    .Logo{
        border-left: 2px solid #004AAD;
        border-right: 2px solid #004AAD;
        padding: 0 2rem;
    }
    .Logo img{
        width: 200px;
    }
    .divLogo{
        display: block;
        text-align: center;
        padding-left: 2%;
    }
    .divLogo p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: 1rem;
    }
    .divLogo img{
        width: 75px;
    }
    .assinatura {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        justify-content: center;
        margin: 0;
        background-color: var(--azulEscuro);
        padding: 1rem 0;
      }
      
      .assinaturaAutor {
        cursor: pointer;
        text-decoration: none;
        margin-left: 0.3rem;
        color: white;
        border-bottom: 1px white dashed;
      }
  }
  
  @media (max-width: 500px) {
    .footer{
        margin: 0;
    }
    .section{
        font-size: 14px;
        display: block;
        background-color: var(--azulMedia);
        justify-content: center;
        align-items: center;        
        text-align: center;
        padding: 2% 0;
    }.divSocial{
        padding: .5rem 1rem;        
    }
    .divSocial img{
        width: 36px;
        color: var(--branco);
        margin: 0.3rem 0 0 1rem;
    }
    .divSocial p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: .5rem;
    }
    .divSocial a{
        cursor: pointer;
    }
    .Logo{
        border-left: 0px solid #004AAD;
        border-right: 0px solid #004AAD;
        border-bottom: 2px solid #004AAD;
        border-top: 2px solid #004AAD;
        padding: 0 2rem;
        margin: 0 15%;
    }
    .Logo img{
        width: 150px;
    }
    .divLogo{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 2%;
    }
    .divLogo p{
        color:#004AAD;
        font-weight: 600;
        padding-bottom: 1rem;
    }
    .divLogo img{
        width: 100px;
    }
    .assinatura {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        justify-content: center;
        margin: 0;
        background-color: var(--azulEscuro);
        padding: .5rem 0;
      }
      
      .assinaturaAutor {
        cursor: pointer;
        text-decoration: none;
        margin-left: 0.3rem;
        color: white;
        border-bottom: 1px white dashed;
      }
  }