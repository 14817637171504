.section{
    display: block;
    justify-content: center;
    text-align: center;
    background-color: var(--azulMedia);
}
.imgMobileL{
    display: none;
}
.imgMobileR{
    display: none;
}
.h2{
    font-size: 60px;
    color: var(--branco);
    margin: 0;
    padding: 2rem 0;
}
.containerLeft{
    display: flex;
    align-items: center;    
    padding: 2% 25% 2% 0;
}
.boxLeft{
    padding-right: 5%;
}
.boxTituloLeft{
    background-color: var(--azulClaro);
    text-align: right;
    border-radius: 0 25px 25px 0;
}
.tituloLeft{
    margin: 0;
    font-size: 60px;
    color: var(--azulEscuro);
    padding: 1% 2% 1% 0;
}
.textoLeft{
    text-align: right;
    font-size: 24px;
    margin: 2% 2% 0 0 ;
    color: var(--branco);
    padding-left: 50%;
}
.img{
    width: 30%;
}


.containerRight{
    display: flex;
    align-items: center;    
    padding: 2% 0 2% 25%;
}
.boxRight{
    padding-left: 5%;
}
.boxTituloRight{
    background-color: var(--azulClaro);
    text-align: left;
    border-radius: 25px 0 0 25px;
}
.tituloRight{
    margin: 0;
    font-size: 60px;
    color: var(--azulEscuro);
    padding: 1% 0 1% 2%;
}
.textoRight{
    text-align: left;
    font-size: 24px;
    margin: 2% 0 0 2% ;
    color: var(--branco);
    padding-right: 50%;
}

@media (max-width: 1440px) {
    .section{
        display: block;
        justify-content: center;
        text-align: center;
        background-color: var(--azulMedia);
    }
    .h2{
        font-size: 60px;
        color: var(--branco);
        margin: 0;
        padding: 2rem 0;
    }
    .containerLeft{
        display: flex;
        align-items: center;    
        padding: 2% 25% 2% 0;
    }
    .boxLeft{
        padding-right: 5%;
    }
    .boxTituloLeft{
        background-color: var(--azulClaro);
        text-align: right;
        border-radius: 0 25px 25px 0;
    }
    .tituloLeft{
        margin: 0;
        font-size: 48px;
        color: var(--azulEscuro);
        padding: 1% 2% 1% 0;
    }
    .textoLeft{
        text-align: right;
        font-size: 24px;
        margin: 2% 2% 0 0 ;
        color: var(--branco);
        padding-left: 30%;
    }
    .img{
        width: 30%;
    }
    
    
    .containerRight{
        display: flex;
        align-items: center;    
        padding: 2% 0 2% 25%;
    }
    .boxRight{
        padding-left: 5%;
    }
    .boxTituloRight{
        background-color: var(--azulClaro);
        text-align: left;
        border-radius: 25px 0 0 25px;
    }
    .tituloRight{
        margin: 0;
        font-size: 48px;
        color: var(--azulEscuro);
        padding: 1% 0 1% 2%;
    }
    .textoRight{
        text-align: left;
        font-size: 24px;
        margin: 2% 0 0 2% ;
        color: var(--branco);
        padding-right: 30%;
    }
}


@media (max-width: 1024px) {
    .h2{
        font-size: 48px;
        color: var(--branco);
        margin: 0;
        padding: 2rem 0;
    }
    .containerLeft{
        display: flex;
        align-items: center;    
        padding: 2% 25% 2% 0;
    }
    .boxLeft{
        padding-right: 5%;
    }
    .boxTituloLeft{
        background-color: var(--azulClaro);
        text-align: right;
        border-radius: 0 25px 25px 0;
    }
    .tituloLeft{
        margin: 0;
        font-size: 36px;
        color: var(--azulEscuro);
        padding: 1% 2% 1% 0;
    }
    .textoLeft{
        text-align: right;
        font-size: 18px;
        margin: 2% 2% 0 0 ;
        color: var(--branco);
        padding-left: 30%;
    }
    .img{
        width: 30%;
    }
    
    
    .containerRight{
        display: flex;
        align-items: center;    
        padding: 2% 0 2% 25%;
    }
    .boxRight{
        padding-left: 5%;
    }
    .boxTituloRight{
        background-color: var(--azulClaro);
        text-align: left;
        border-radius: 25px 0 0 25px;
    }
    .tituloRight{
        margin: 0;
        font-size: 36px;
        color: var(--azulEscuro);
        padding: 1% 0 1% 2%;
    }
    .textoRight{
        text-align: left;
        font-size: 18px;
        margin: 2% 0 0 2% ;
        color: var(--branco);
        padding-right: 30%;
    }
}


@media (max-width: 768px) {
    .h2{
        font-size: 36px;
        color: var(--branco);
        margin: 0;
        padding: 2rem 0;
    }
    .containerLeft{
        display: flex;
        align-items: center;    
        padding: 2% 25% 2% 0;
    }
    .boxLeft{
        padding-right: 5%;
    }
    .boxTituloLeft{
        background-color: var(--azulClaro);
        text-align: right;
        border-radius: 0 25px 25px 0;
    }
    .tituloLeft{
        margin: 0;
        font-size: 24px;
        color: var(--azulEscuro);
        padding: 1% 2% 1% 0;
    }
    .textoLeft{
        text-align: right;
        font-size: 14px;
        margin: 2% 2% 0 0 ;
        color: var(--branco);
        padding-left: 30%;
    }
    .img{
        width: 30%;
    }
    
    
    .containerRight{
        display: flex;
        align-items: center;    
        padding: 2% 0 2% 25%;
    }
    .boxRight{
        padding-left: 5%;
    }
    .boxTituloRight{
        background-color: var(--azulClaro);
        text-align: left;
        border-radius: 25px 0 0 25px;
    }
    .tituloRight{
        margin: 0;
        font-size: 24px;
        color: var(--azulEscuro);
        padding: 1% 0 1% 2%;
    }
    .textoRight{
        text-align: left;
        font-size: 14px;
        margin: 2% 0 0 2% ;
        color: var(--branco);
        padding-right: 30%;
    }
}


@media (max-width: 500px) {
    .h2{
        font-size: 24px;
        color: var(--branco);
        margin: 0;
        padding: 2rem 0;
    }
    .containerLeft{
        display: flex;
        align-items: center;    
        padding: 2% 15% 2% 0;
    }
    .boxLeft{
        text-align: center;
        display:flex;
        flex-direction: column;
        padding-right: 5%;
    }
    .boxTituloLeft{
        background-color: var(--azulClaro);
        text-align: right;
        border-radius: 0 25px 25px 0;
    }
    .tituloLeft{
        margin: 0;
        font-size: 18px;
        color: var(--azulEscuro);
        padding: 1% 2% 1% 0;
    }
    .textoLeft{
        text-align: right;
        font-size: 14px;
        margin: 2% 2% 0 0 ;
        color: var(--branco);
        padding-left: 10%;
    }
    .img{
        display: none;
    }
    .imgMobileL{        
        display: block;
        width: 200px;
        margin-left: 30%;
    }
    .imgMobileR{        
        display: block;
        width: 200px;
    }
    
    .containerRight{
        display: flex;
        align-items: center;    
        padding: 2% 0 2% 15%;
    }
    .boxRight{
        padding-left: 5%;
    }
    .boxTituloRight{
        background-color: var(--azulClaro);
        text-align: left;
        border-radius: 25px 0 0 25px;
    }
    .tituloRight{
        margin: 0;
        font-size: 18px;
        color: var(--azulEscuro);
        padding: 1% 0 1% 2%;
    }
    .textoRight{
        text-align: left;
        font-size: 14px;
        margin: 2% 0 0 2% ;
        color: var(--branco);
        padding-right: 10%;
    }
}