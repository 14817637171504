* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .section{
    padding: 2% 0;
    background: var(--azulClaro);
    text-align: center;
  }
  .h2 {
    color: var(--azulMedia);
    font-size: 60px;
    margin: 0;
    padding-bottom: 1.5rem;
  }
  
  .form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .formgroup {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 500px;
  }
  
  .checkboxgroup {
    flex-direction: row;
    align-items: center;
  }
  
  .checkboxgroup label {
    font-size: 0.825rem;
    margin-left: 10px;
  }
  
  .formgroup label {
    color: #FFF;
    font-weight: 600;
    padding-bottom: 0.375rem;
  }
  
  .formgroup input {
    padding: 12px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--branco);
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    outline: 1px solid 444;
  }
  .formgroup textarea{
    padding: 12px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--branco);
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    outline: 1px solid 444;
    height: 150px;
  }
  
  .formgroup input::placeholder {
    color: #999;
  }
  
  .formgroup .inputerror {
    outline: 1px solid rgb(255, 72, 72);
  }
  
  .formgroup .errormessage {
    color: rgb(255, 72, 72);
    font-size: 0.75rem;
    margin-top: 8px;
  }
  
  .formgroup select {
    padding: 12px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #444;
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .formgroup button {
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: var(--branco);
    background-color: var(--azulMedia);
    font-weight: 500;
    font-size: 1rem;
    margin-top: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 500ms ease;
  }
  
  .formgroup button:hover {
    cursor: pointer;
    background-color: #61dbfb;
  }

  
  @media (max-width: 1440px) {
    .h2 {
      color: var(--azulMedia);
      font-size: 48px;
      margin: 0;
      padding-bottom: 1.5rem;
    }
  }
  @media (max-width: 1024) {
    .h2 {
      color: var(--azulMedia);
      font-size: 36px;
      margin: 0;
      padding-bottom: 1.5rem;
    }
  }
  @media (max-width: 768px) {
    .h2 {
      color: var(--azulMedia);
      font-size: 36px;
      margin: 0;
      padding-bottom: 1.5rem;
    }
  }

  @media (max-width: 500px) {
    
  .section{
    padding: 2% 0;
    background: var(--azulClaro);
  }
  .h2 {
    color: var(--azulMedia);
    font-size: 24px;
    margin: 0;
    padding-bottom: 1.5rem;
  }
  
  .form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .formgroup {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 300px;
  }

  .checkboxgroup label {
    font-size: 0.6rem;
    margin-left: 10px;
  }
  
  .formgroup label {
    color: #FFF;
    font-weight: 600;
    padding-bottom: 0.375rem;
  }
  
  .formgroup input {
    padding: 12px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--branco);
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    outline: 1px solid 444;
  }
  .formgroup textarea{
    padding: 12px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: var(--branco);
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    outline: 1px solid 444;
    height: 100px;
  }
  
  .formgroup input::placeholder {
    color: #999;
  }
  
  .formgroup .inputerror {
    outline: 1px solid rgb(255, 72, 72);
  }
  
  .formgroup .errormessage {
    color: rgb(255, 72, 72);
    font-size: 0.5rem;
    margin-top: 8px;
  }
  
  .formgroup select {
    padding: 12px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #444;
    color: #000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .formgroup button {
    border: none;
    padding: 10px;
    border-radius: 5px;
    color: var(--branco);
    background-color: var(--azulEscuro);
    font-weight: 500;
    font-size: .7rem;
    margin-top: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 500ms ease;
  }
  
  .formgroup button:hover {
    cursor: pointer;
    background-color: #61dbfb;
  }
  }