.img {
    width: 100px;
}
.h2{
    margin: 0;
    color: var(--azulMedia);
    font-size: 60px;
}

.section{
    padding: 2% 15%;
    text-align: center;
}
.h3{
    padding: 0 15% 3% 15%;
    color: var(--azulEscuro);
    font-size: 36px;
    margin: 0;
}
.strong{
    color: var(--azulMedia)
}
.boxImg{
    padding-bottom: 2rem;
}

@media (max-width: 1440px) {
    .h3{
        padding: 0 5% 3% 5%;
        color: var(--azulEscuro);
        font-size: 36px;
        margin: 0;
    }
    .h2{
        margin: 0;
        color: var(--azulMedia);
        font-size: 48px;
    }
}

@media (max-width: 1024px) {
    .h3{
        padding: 0 5% 3% 5%;
        color: var(--azulEscuro);
        font-size: 24px;
        margin: 0;
    }
    .h2{
        margin: 0;
        color: var(--azulMedia);
        font-size: 36px;
    }
    .section{
        padding: 2% 10%;
        text-align: center;
    }
}
@media (max-width: 768px) {
    .h3{
        padding: 0 5% 3% 5%;
        color: var(--azulEscuro);
        font-size: 24px;
        margin: 0;
    }
    .h2{
        margin: 0;
        color: var(--azulMedia);
        font-size: 24px;
    }
    .section{
        padding: 2% 10%;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .h3{
        padding: 0 5% 3% 5%;
        color: var(--azulEscuro);
        font-size: 16px;
        margin: 0;
    }
    .section{
        padding: 2% 10%;
        text-align: center;
    }
}
