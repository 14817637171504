.section {
  display: block;
  text-align: center;
  margin: 2% 10% 2% 10%;
}
.h2 {
  color: var(--azulEscuro);
  font-size: 60px;
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.boxL{
  display: block;
}
.fig{
  padding: 0 3rem;
}

.h3 {
  display: flex;
  height: 3rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 20px 0px 0px 20px;
  background: var(--azulClaro);
  border: 5px solid var(--azulClaro);
  font-size: 36px;
  color: var(--azulEscuro);
  padding: 3rem 0;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2% 15%;
}
.p {
  display: flex;
  height: 3rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 0px 0px 15px 15px;
  border: 5px solid var(--azulEscuro);
  font-weight: 600;
  color: var(--azulEscuro);
  padding: 3rem 0;
}
.h3 {
  display: flex;
  height: 3rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 15px 15px 0px 0px;
  background: var(--azulClaro);
  border: 5px solid var(--azulClaro);
  font-size: 36px;
  color: var(--azulEscuro);
  padding: 3rem 0;
}
@media (max-width: 1440px) {
  .section {
    display: block;
    text-align: center;
    margin: 2% 5% 2% 5%;
  }
  .h2 {
    font-size: 48px;
  }
  .fig{
    padding: 0 1rem;
  }  
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2% 10%;
  }
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2% 15%;
  }
  .p {
    display: flex;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0px 0px 15px 15px;
    border: 5px solid var(--azulEscuro);
    font-weight: 600;
    color: var(--azulEscuro);
    padding: 3rem 0;
  }
  .h3 {
    display: flex;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 15px 15px 0px 0px;
    background: var(--azulClaro);
    border: 5px solid var(--azulClaro);
    font-size: 36px;
    color: var(--azulEscuro);
    padding: 3rem 0;
  }
}


@media (max-width: 1024px) {
  .section {
    display: block;
    text-align: center;
    margin: 2% 2% 2% 2%;
  }
  .h2 {
    font-size: 36px;
  }
  .fig{
    padding: 0 1rem;
    width: 30vw;
  }  

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2% 10%;
  }
  .p {
    display: flex;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0px 0px 15px 15px;
    border: 5px solid var(--azulEscuro);
    font-weight: 600;
    color: var(--azulEscuro);
    padding: 3rem 0;
    font-size: 12px;
  }
  .h3 {
    display: flex;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 15px 15px 0px 0px;
    background: var(--azulClaro);
    border: 5px solid var(--azulClaro);
    font-size: 24px;
    color: var(--azulEscuro);
    padding: 3rem 0;
  }
}


@media (max-width: 768px) {
  .section {
    display: block;
    text-align: center;
    margin: 2% 2% 2% 2%;
  }
  .h2 {
    font-size: 36px;
  }
  .fig{
    padding: 0 1rem;
    width: 25vw;
  }  

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2% 10%;
  }
  .p {
    display: flex;
    height: 3rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0px 0px 15px 15px;
    border: 5px solid var(--azulEscuro);
    font-weight: 600;
    color: var(--azulEscuro);
    padding: 2rem 0;
    font-size: 12px;
  }
  .h3 {
    display: flex;
    height: 2rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 15px 15px 0px 0px;
    background: var(--azulClaro);
    border: 5px solid var(--azulClaro);
    font-size: 18px;
    color: var(--azulEscuro);
    padding: 2rem 0;
  }
}



@media (max-width: 500px) {
  .section {
    display: block;
    text-align: center;
    margin: 2% 2% 2% 2%;
  }
  .h2 {
    font-size: 24px;
  }
  .fig{
    padding: 0 0.1rem;
    width: 25vw;
  }  

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 4% 5%;
  }
  .p {
    display: flex;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0px 0px 15px 15px;
    border: 5px solid var(--azulEscuro);
    font-weight: 600;
    color: var(--azulEscuro);
    padding: 2rem 0;
    font-size: 11px;
  }
  .h3 {
    display: flex;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 15px 15px 0px 0px;
    background: var(--azulClaro);
    border: 5px solid var(--azulClaro);
    font-size: 18px;
    color: var(--azulEscuro);
    padding: 1.5rem 0;
  }
}